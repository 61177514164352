<script setup lang="ts">
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthView } from '@base/types'

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const toast = useToast()
const { registerWithPassword } = useAuth()
const { emailValidation, passwordValidation, companyIdNumberValidation } = useValidations()
const { platform } = useAppConfig()

const validationSchema = yup.object({
  name: yup.string().required('Jméno je povinné').min(5, 'Zadejte minimálně 5 znaků'),
  email: emailValidation,
  password: passwordValidation,
  consent: yup.boolean().required().oneOf([true], 'Musíte souhlasit se zpracováním osobních údajů.'),
  isExchangeOfficeOwner: yup.boolean(),
  companyIdNumber: companyIdNumberValidation,
  companyName: yup.string().required('Jméno směnárny je povinné.').min(6, 'Zadejte minimálně 6 znaků'),
  url: yup.string().url('Zadejte platnou URL adresu'),
})

const { meta, isSubmitting, handleSubmit } = useForm({ validationSchema })

const onSubmit = handleSubmit(async values => {
  const error = await registerWithPassword({
    email: values.email,
    password: values.password,
  },
  {
    name: values.name,
    isExchangeOfficeOwner: true,
    companyIdNumber: values.companyIdNumber,
    companyName: values.companyName,
    url: values.url,
    platformId: platform.id,
    invitedByUserId: useCookie('bitbeli-invitedByUserId').value,
  })

  if (error)
    return toast.error('Zadaný email má již vytvořený účet.')

  emit('close')
  navigateTo('/signup-success')
})
</script>

<template>
  <h4 :data-test="getCypressId('sign-up-header')" heading4 font-800 my-16px>
    Vytvořit nový účet
  </h4>
  <div>
    <div
      pb-0px flex-wrap flex
      gap-4px
      fubex-rounded-md text-left
      font-400 paragraph-sm text-neutral-900
      w-full justify-start items-start
      rounded-b-0 mb-16px
      border-b-3px border-primary-500 dark:border-primary-300
    />
    <form @submit="onSubmit">
      <div v-auto-animate flex flex-col gap-16px>
        <FInputVee name="companyName" cypress-prefix="signup" type="text" label="Směnárna" placeholder="Zadejte název směnárny" />
        <FInputVee name="companyIdNumber" cypress-prefix="signup" type="text" label="IČO" placeholder="Zadejte IČO směnárny" />
        <FInputVee name="name" cypress-prefix="signup" type="text" label="Jednatel" placeholder="Zadejte jméno a příjmení" />
        <FInputVee name="url" type="text" label="Webová stránka" placeholder="Zadejte URL směnárny" />
        <FInputVee name="email" type="text" label="Vaše e-mailová adresa" placeholder="Zadejte Váš e-mail" />
        <FInputVee name="password" type="password" label="Heslo" placeholder="Zadejte Vaše heslo" />
      </div>
      <div mt-40px flex>
        <FCheckboxVee name="consent">
          Souhlasím se
          <NuxtLink :to="`${platform.domain}/docs/vop.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            všeobecnými obchodními podmínkami
          </NuxtLink>
          a se zpracováním
          <NuxtLink :to="`${platform.domain}/docs/gdpr.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            osobních údajů
          </NuxtLink>.
        </FCheckboxVee>
      </div>
      <FButton name="signup-register" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        Vytvořit nový účet
      </FButton>
    </form>

    <div mt-32px paragraph-md text-center>
      Pokud máte již účet, můžete se <button type="button" golden-link @click="emit('changeView', 'login')">
        Přihlásit
      </button>.
    </div>
  </div>
</template>
